import * as React from "react";
import type { SVGProps } from "react";
const SvgConvertColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="url(#ConvertColor_svg__a)"
      d="M94.534 40.707C81.155 30.183 72.67 15.5 68.266 6.852l-2.203-4.65-.166-.345a1 1 0 0 0-1.8-.005l-.889 1.819-1.631 3.508c-4.406 8.729-12.971 23.167-26.268 33.528-20.231 15.989-32.55 34.915-32.55 58.572 0 30.183 23.576 63.059 62.244 63.059 38.667 0 62.08-32.876 62.08-63.06 0-23.656-12.237-42.582-32.55-58.571"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M37.945 86.504C42.046 75.688 52.505 68 64.758 68c12.991 0 23.955 8.64 27.479 20.478a3.583 3.583 0 1 1-6.87 2.044c-2.643-8.883-10.875-15.355-20.61-15.355-9.479 0-17.525 6.134-20.385 14.65l5.924-3.42a3.583 3.583 0 1 1 3.583 6.206L41.466 99.77a3.584 3.584 0 0 1-4.895-1.312l-7.166-12.413a3.583 3.583 0 1 1 6.206-3.583zm52.824 13.868c.918.246 1.7.847 2.176 1.67l7.166 12.413a3.583 3.583 0 1 1-6.206 3.583l-2.334-4.042C87.47 124.812 77.011 132.5 64.758 132.5c-12.991 0-23.956-8.639-27.479-20.478a3.582 3.582 0 1 1 6.869-2.044c2.644 8.883 10.876 15.355 20.61 15.355 9.48 0 17.526-6.134 20.386-14.65l-5.924 3.42a3.583 3.583 0 1 1-3.583-6.206l12.413-7.167a3.584 3.584 0 0 1 2.719-.358"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="ConvertColor_svg__a"
        x1={28.62}
        x2={150.54}
        y1={162.338}
        y2={110.817}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#232C61" />
        <stop offset={0.776} stopColor="#45A8AA" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgConvertColor;
