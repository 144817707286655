import * as React from "react";
import type { SVGProps } from "react";
const SvgConvertWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M68.266 6.853c4.405 8.647 12.889 23.33 26.268 33.854 20.312 15.989 32.549 34.915 32.549 58.572 0 30.183-23.413 63.059-62.08 63.059-38.668 0-62.243-32.876-62.243-63.059 0-23.657 12.318-42.583 32.549-58.572 13.297-10.36 21.862-24.8 26.268-33.528l1.631-3.508.89-1.82a1 1 0 0 1 1.799.006l.166.346zM64.757 68c-12.253 0-22.711 7.688-26.813 18.504l-2.333-4.042a3.583 3.583 0 1 0-6.207 3.584l7.167 12.412a3.583 3.583 0 0 0 4.895 1.312l12.413-7.167a3.583 3.583 0 1 0-3.584-6.206l-5.924 3.42c2.86-8.516 10.907-14.65 20.386-14.65 9.735 0 17.967 6.472 20.61 15.355a3.583 3.583 0 1 0 6.87-2.044C88.712 76.639 77.748 68 64.756 68m28.187 34.042a3.584 3.584 0 0 0-4.895-1.312l-12.413 7.167a3.583 3.583 0 1 0 3.584 6.206l5.923-3.42c-2.86 8.516-10.906 14.65-20.385 14.65-9.735 0-17.967-6.471-20.61-15.355a3.583 3.583 0 1 0-6.87 2.044c3.524 11.839 14.488 20.478 27.48 20.478 12.253 0 22.711-7.688 26.813-18.504l2.333 4.042a3.584 3.584 0 0 0 6.207-3.583z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgConvertWhite;
