import * as React from "react";
import type { SVGProps } from "react";
const SvgCloudPlatformWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M68.237 6.853c4.405 8.647 12.889 23.33 26.267 33.854 20.313 15.989 32.549 34.915 32.549 58.572 0 30.183-23.412 63.059-62.08 63.059-38.667 0-62.243-32.876-62.243-63.059 0-23.657 12.319-42.583 32.55-58.572 13.297-10.36 21.862-24.8 26.267-33.528L63.18 3.67l.89-1.82a1 1 0 0 1 1.799.006l.166.346zM55.934 75.528c3.936-4.458 9.7-7.278 16.125-7.278 11.874 0 21.5 9.626 21.5 21.5 0 .63-.028 1.255-.081 1.873 4.327 2.465 7.247 7.118 7.247 12.46 0 7.917-6.417 14.334-14.333 14.334h-43c-7.916 0-14.333-6.417-14.333-14.334 0-6.68 4.57-12.294 10.756-13.883-.004-.15-.006-.3-.006-.45 0-7.916 6.417-14.333 14.333-14.333.606 0 1.204.038 1.792.111m16.125-.111c-4.861 0-9.158 2.416-11.756 6.13a3.583 3.583 0 0 1-4.01 1.364 7.163 7.163 0 0 0-2.151-.328 7.167 7.167 0 0 0-7.167 7.167c0 .849.146 1.655.411 2.4a3.583 3.583 0 0 1-3.579 4.778 7.166 7.166 0 1 0-.415 14.322h43a7.166 7.166 0 0 0 7.167-7.167 7.17 7.17 0 0 0-5.082-6.86 3.583 3.583 0 0 1-2.452-4.234c.24-1.037.367-2.121.367-3.239 0-7.916-6.417-14.333-14.333-14.333m-39.417 53.75a3.584 3.584 0 0 1 3.583-3.584h3.584a3.584 3.584 0 0 1 0 7.167h-3.584a3.583 3.583 0 0 1-3.583-3.583m14.333 0a3.584 3.584 0 0 1 3.584-3.584h3.583a3.584 3.584 0 0 1 0 7.167h-3.583a3.583 3.583 0 0 1-3.584-3.583m14.334 0a3.584 3.584 0 0 1 3.583-3.584h28.667a3.584 3.584 0 0 1 0 7.167H64.892a3.583 3.583 0 0 1-3.583-3.583"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloudPlatformWhite;
