import * as React from "react";
import type { SVGProps } from "react";
const SvgCampaignStudioColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="url(#CampaignStudioColor_svg__a)"
      d="M94.504 40.707C81.126 30.183 72.642 15.5 68.237 6.852l-2.203-4.65-.166-.345a1 1 0 0 0-1.8-.005l-.89 1.819-1.63 3.508c-4.406 8.729-12.971 23.167-26.268 33.528C15.049 56.696 2.73 75.622 2.73 99.279c0 30.183 23.576 63.059 62.244 63.059 38.667 0 62.079-32.876 62.079-63.06 0-23.656-12.236-42.582-32.549-58.571"
    />
    <path
      fill="#fff"
      d="M86.88 77.137a3.583 3.583 0 0 1 5.055.353 35.834 35.834 0 0 1 1.116 45.668 3.582 3.582 0 1 1-5.632-4.431 28.67 28.67 0 0 0-.892-36.535 3.583 3.583 0 0 1 .353-5.055M72.059 100.999a7.167 7.167 0 1 1-14.334 0 7.167 7.167 0 0 1 14.334 0"
    />
    <path
      fill="#fff"
      d="M81.118 86.894a3.583 3.583 0 0 0-5.409 4.702 14.333 14.333 0 0 1 .447 18.267 3.583 3.583 0 0 0 5.632 4.432 21.5 21.5 0 0 0-.67-27.401M53.52 86.698a3.583 3.583 0 0 1 .424 5.05 14.333 14.333 0 0 0-.191 18.271 3.583 3.583 0 0 1-5.57 4.511 21.502 21.502 0 0 1 .287-27.408 3.583 3.583 0 0 1 5.05-.424"
    />
    <path
      fill="#fff"
      d="M42.996 82.496a3.583 3.583 0 1 0-5.474-4.626 35.833 35.833 0 0 0-.478 45.679 3.583 3.583 0 1 0 5.57-4.51 28.666 28.666 0 0 1 .382-36.543"
    />
    <defs>
      <linearGradient
        id="CampaignStudioColor_svg__a"
        x1={15.105}
        x2={177.127}
        y1={188.882}
        y2={119.696}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212654" />
        <stop offset={0.1} stopColor="#751D5F" />
        <stop offset={0.2} stopColor="#AA1866" />
        <stop offset={0.3} stopColor="#C8146A" />
        <stop offset={0.4} stopColor="#D7136C" />
        <stop offset={0.5} stopColor="#DE126D" />
        <stop offset={0.6} stopColor="#E0126D" />
        <stop offset={0.7} stopColor="#E0126D" />
        <stop offset={0.8} stopColor="#E1116E" />
        <stop offset={0.9} stopColor="#E1116E" />
        <stop offset={1} stopColor="#E1116E" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgCampaignStudioColor;
