import * as React from "react";
import type { SVGProps } from "react";
const SvgCmsColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="url(#CMSColor_svg__a)"
      d="M94.504 40.707C81.126 30.183 72.642 15.5 68.237 6.852l-2.203-4.65-.166-.345a1 1 0 0 0-1.8-.005l-.89 1.819-1.63 3.508c-4.406 8.729-12.971 23.167-26.268 33.528C15.049 56.696 2.73 75.622 2.73 99.279c0 30.183 23.576 63.059 62.244 63.059 38.667 0 62.079-32.876 62.079-63.06 0-23.656-12.236-42.582-32.549-58.571"
    />
    <path
      fill="#fff"
      d="M64.892 107.666a7.167 7.167 0 1 0 0-14.334 7.167 7.167 0 0 0 0 14.334"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M57.548 64.666a3.583 3.583 0 0 0-3.583 3.583v9.585a25.725 25.725 0 0 0-3.626 2.057l-8.602-4.852a3.583 3.583 0 0 0-4.846 1.298L29.557 88.75a3.583 3.583 0 0 0 1.324 4.944l8.538 4.816a24.592 24.592 0 0 0 0 3.976l-8.538 4.817a3.582 3.582 0 0 0-1.324 4.943l7.334 12.413a3.583 3.583 0 0 0 4.846 1.298l8.6-4.851a25.654 25.654 0 0 0 3.628 2.058v9.585a3.584 3.584 0 0 0 3.583 3.584h14.669a3.584 3.584 0 0 0 3.583-3.584v-9.545a25.741 25.741 0 0 0 3.695-2.085l8.552 4.824c1.7.959 3.853.382 4.846-1.298l7.334-12.413a3.583 3.583 0 0 0-1.324-4.943l-8.474-4.781a24.575 24.575 0 0 0 0-4.02l8.474-4.78a3.582 3.582 0 0 0 1.324-4.944L92.893 76.35a3.583 3.583 0 0 0-4.846-1.298l-8.554 4.825a25.72 25.72 0 0 0-3.693-2.084V68.25a3.583 3.583 0 0 0-3.583-3.583zm3.583 15.578v-8.411h7.502v8.381c0 1.53.973 2.893 2.42 3.39a18.515 18.515 0 0 1 5.865 3.298 3.583 3.583 0 0 0 4.057.369l7.534-4.25 3.688 6.24-7.403 4.177a3.583 3.583 0 0 0-1.759 3.793 17.439 17.439 0 0 1 0 6.535 3.582 3.582 0 0 0 1.76 3.792l7.402 4.176-3.688 6.242-7.533-4.25a3.583 3.583 0 0 0-4.057.37 18.51 18.51 0 0 1-5.866 3.299 3.583 3.583 0 0 0-2.42 3.389v8.382h-7.502v-8.412a3.583 3.583 0 0 0-2.406-3.384 18.51 18.51 0 0 1-5.808-3.286 3.584 3.584 0 0 0-4.06-.372l-7.582 4.278-3.688-6.242 7.464-4.211a3.583 3.583 0 0 0 1.76-3.788 17.444 17.444 0 0 1 0-6.501 3.583 3.583 0 0 0-1.76-3.789l-7.464-4.211 3.688-6.242 7.584 4.279a3.583 3.583 0 0 0 4.059-.372 18.514 18.514 0 0 1 5.807-3.285 3.583 3.583 0 0 0 2.406-3.384"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="CMSColor_svg__a"
        x1={15.105}
        x2={177.127}
        y1={188.882}
        y2={119.696}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212654" />
        <stop offset={0.1} stopColor="#23598B" />
        <stop offset={0.2} stopColor="#247AAF" />
        <stop offset={0.3} stopColor="#258DC5" />
        <stop offset={0.4} stopColor="#2599D2" />
        <stop offset={0.5} stopColor="#259FD8" />
        <stop offset={0.6} stopColor="#25A1DB" />
        <stop offset={0.7} stopColor="#25A2DC" />
        <stop offset={0.8} stopColor="#25A2DC" />
        <stop offset={0.9} stopColor="#26A2DC" />
        <stop offset={1} stopColor="#26A3DD" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgCmsColor;
