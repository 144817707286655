import React, { HTMLAttributes } from 'react';

import * as ProductLogos from '../../dist/product-logos';
import { ProductLogoMachineNames, ProductLogoNames } from '@/types'

export const ProductLogoOptions:{
  [K in ProductLogoMachineNames | ProductLogoNames]: ProductLogoNames
} = {
    campaign_studio: 'CampaignStudio',
    cdp: 'Cdp',
    cloud_platform: 'CloudPlatform',
    cms: 'Cms',
    dam: 'Dam',
    site_factory: 'SiteFactory',
    drupal_cloud_addon: 'DrupalCloudAddOn',
    marketing_cloud_addon: 'MarketingCloudAddOn',
    monsido: 'Monsido',
    convert: 'Convert',
    seo: 'Seo',

    // Deprecated options
    MarketingCloudAddOn: 'MarketingCloudAddOn',
    DrupalCloudAddOn: 'DrupalCloudAddOn',
    SiteFactory: 'SiteFactory',
    Dam: 'Dam',
    Cms: 'Cms',
    CloudPlatform: 'CloudPlatform',
    Cdp: 'Cdp',
    CampaignStudio: 'CampaignStudio',
    Monsido: 'Monsido',
    Convert: 'Convert',
    Seo: 'Seo'
}

interface ProductLogoProps extends HTMLAttributes<any> {
    name: keyof typeof ProductLogoOptions;
    logoStyle?: 'Color' | 'White',
    size?: number
}

/**
 * Primary UI component for user interaction
 */
export const ProductLogo = ({
  name = 'drupal_cloud_addon',
  logoStyle = 'Color',
  size = 256,
  ...props
}: ProductLogoProps) => {

  if (Object.keys(ProductLogoOptions).indexOf(name) == -1) {
    return <></>
  }
  const type = ProductLogoOptions[name] + logoStyle as keyof typeof ProductLogos

  const Tag = ProductLogos[type]

  if (Tag == undefined) {
    return <></>
  }

  let tailwindHeightMap:({
    [index: number]: string
  }) = {}
  let viewBoxHeight;

  if (name == 'drupal_cloud_addon') {
    return (<Tag preserveAspectRatio="xMaxYMid meet" viewBox={`0 0 160 160`} {...props} />);
  }
  else if (name == 'marketing_cloud_addon') {
    return (<Tag preserveAspectRatio="xMaxYMid meet" viewBox={`0 0 160 160`} {...props} />);
  }
  else {
    viewBoxHeight = (size / 256) * 326;
    tailwindHeightMap = {
      128: 'h-[164px]',
      256: 'h-[82px]',
      385: 'h-[62px]',
    }
  }

  return <Tag {...props} />
};
