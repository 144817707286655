'use client'
import { Body } from "@/components/wysiwyg/Body";
import { searchResultHit } from "../../../lib/search/server-actions.server";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import classNames from "classnames";
import { sendGTMEvent } from '@next/third-parties/google'
import { useProducts } from "@/contexts/ProductsProvider";
import { DrupalTaxonomyTermProduct } from "@/types";
import { ProductLogo } from "@/components/assets/ProductLogo";

export const SearchResult = ({href, highlight, sourceName, objName, metadata}: searchResultHit) => {
  const pathname = usePathname()
  const router = useRouter()
  const { products } = useProducts()

  // Hack to make production links relative.
  let rel = href.startsWith('https://docs.acquia.com/') ? href.replace('https://docs.acquia.com', '') : href

  // Conductor source stores URL in metadata.
  if (sourceName == '1_21_conductor_knowledge_base') {
    rel = metadata.filter(m => m.key == 'View href').map(m => m.value[0])[0]
    href = rel;
  }

  const isActive = rel == pathname
  const target = rel.startsWith('http') ? '_blank' : undefined

  const goto = () => {
    sendGTMEvent({
      event: 'searchResultSelect',
      value: rel
    })
    if (target && window && rel) {
      window.open(rel, target)?.focus()
    }
    else {
      router.push(rel)
    }
  }

  const product = findProduct(rel, sourceName, products)
  
  return (
    <div onClick={goto} className={classNames("mb-2 p-4 rounded hover:cursor-pointer border-gray-400 border-2 hover:bg-gray-400", isActive ? 'bg-gray-400' : '')}>
      { product !== undefined && (
        <span className="flex gap-1 items-end font-medium">
          <ProductLogo name={product.field_product_logo} className="w-4 pb-1" /> <span>{product.name}</span>
        </span>
        
      )}
      <h2 className="font-display text-lg my-1 text-navy-600">
        <Link target={target} href={rel} className="">
          {highlight.TitleToDisplayString}
        </Link>
      </h2>
      <div>
        <Body value={highlight.SummaryToDisplay[0]} />
      </div>
      <p className="italic text-blue-800 underline text-sm my-1"><Link href={rel} target={target}>{href}</Link></p>
    </div>
  )
}

function findProduct(href: string, sourceName: string, products: DrupalTaxonomyTermProduct[]) {
  // Return Site Studio.
  if (sourceName == 'server_docs_site_studio') {
    return products.filter(p => {
      return p.id == 'e7defd83-0702-4338-9f41-fd5028641dfd'
    })[0]
  }
  // Acquia SEO powered by Conductor.
  if (sourceName == '1_21_conductor_knowledge_base') {
    return products.filter(p => {
      return p.id == 'a3db1526-a564-4006-ac1a-33eac348d69e'
    })[0]
  }
  const matches = products.filter(p => {
    return href.startsWith(p.path.alias)
  })
  return matches.length ? matches.sort((a, b) => a.path.alias.length > b.path.alias.length ? -1 : 1)[0] : undefined
}
