import * as React from "react";
import type { SVGProps } from "react";
const SvgDamWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M68.237 6.853c4.405 8.647 12.889 23.33 26.267 33.854 20.313 15.989 32.549 34.915 32.549 58.572 0 30.183-23.412 63.059-62.08 63.059-38.667 0-62.243-32.876-62.243-63.059 0-23.657 12.319-42.583 32.55-58.572 13.297-10.36 21.862-24.8 26.267-33.528L63.18 3.67l.89-1.82a1 1 0 0 1 1.799.006l.166.346zm13.782 66.544c1.058 2.077 3.097 5.605 6.31 8.133 4.881 3.842 7.821 8.389 7.821 14.073 0 6.665-4.752 13.877-12.735 15L69.309 86.421c1.26-1.757 2.878-3.379 4.792-4.89 3.195-2.49 5.252-5.959 6.31-8.056l.393-.843.431-.882.255.53zm-52.183 6.008a3.583 3.583 0 0 1 4.901 1.29l25.083 43a3.583 3.583 0 1 1-6.19 3.611l-25.083-43a3.583 3.583 0 0 1 1.29-4.901m19.709 0a3.583 3.583 0 0 1 4.9 1.29l25.084 43a3.583 3.583 0 1 1-6.19 3.611l-25.084-43a3.584 3.584 0 0 1 1.29-4.901"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDamWhite;
