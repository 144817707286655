import * as React from "react";
import type { SVGProps } from "react";
const SvgCdpWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M68.237 6.853c4.405 8.647 12.889 23.33 26.267 33.854 20.313 15.989 32.549 34.915 32.549 58.572 0 30.183-23.412 63.059-62.08 63.059-38.667 0-62.243-32.876-62.243-63.059 0-23.657 12.319-42.583 32.55-58.572 13.297-10.36 21.862-24.8 26.267-33.528L63.18 3.67l.89-1.82a1 1 0 0 1 1.799.006l.166.346zm-3.345 57.814a35.833 35.833 0 1 0 0 71.666 35.833 35.833 0 0 0 0-71.666m-10.97 9.349a28.667 28.667 0 1 1 21.94 52.97 28.667 28.667 0 0 1-21.94-52.97M64.892 79a21.501 21.501 0 0 0-15.203 36.703 21.508 21.508 0 0 0 23.43 4.661 21.517 21.517 0 0 0 11.636-11.636A21.5 21.5 0 0 0 64.892 79m-5.485 8.258a14.333 14.333 0 1 1 10.97 26.485 14.333 14.333 0 0 1-10.97-26.485m12.68 13.242a7.167 7.167 0 1 1-14.334 0 7.167 7.167 0 0 1 14.334 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCdpWhite;
