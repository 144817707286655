import * as React from "react";
import type { SVGProps } from "react";
const SvgSeoWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M68.266 6.853c4.405 8.647 12.889 23.33 26.268 33.854 20.312 15.989 32.549 34.915 32.549 58.572 0 30.183-23.413 63.059-62.08 63.059-38.668 0-62.243-32.876-62.243-63.059 0-23.657 12.318-42.583 32.549-58.572 13.297-10.36 21.862-24.8 26.268-33.528l1.631-3.508.89-1.82a1 1 0 0 1 1.799.006l.166.346zM57.1 85.637c5.597-5.597 14.673-5.597 20.27 0 5.598 5.598 5.598 14.673 0 20.271-5.597 5.597-14.673 5.597-20.27 0-5.598-5.598-5.598-14.673 0-20.27m25.338-5.067c-8.397-8.396-22.01-8.396-30.406 0-7.533 7.533-8.307 19.265-2.323 27.661l-12.88 12.88a3.583 3.583 0 1 0 5.068 5.067l12.88-12.879c8.396 5.984 20.128 5.209 27.66-2.324 8.397-8.396 8.397-22.009 0-30.405"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSeoWhite;
