import * as React from "react";
import type { SVGProps } from "react";
const SvgCampaignStudioWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M68.237 6.853c4.405 8.647 12.889 23.33 26.267 33.854 20.313 15.989 32.549 34.915 32.549 58.572 0 30.183-23.412 63.059-62.08 63.059-38.667 0-62.243-32.876-62.243-63.059 0-23.657 12.319-42.583 32.55-58.572 13.297-10.36 21.862-24.8 26.267-33.528L63.18 3.67l.89-1.82a1 1 0 0 1 1.799.006l.166.346zM86.88 77.138a3.583 3.583 0 0 1 5.055.353 35.834 35.834 0 0 1 1.116 45.668 3.583 3.583 0 1 1-5.632-4.431 28.67 28.67 0 0 0-.892-36.535 3.583 3.583 0 0 1 .353-5.055M72.059 101a7.167 7.167 0 1 1-14.334 0 7.167 7.167 0 0 1 14.334 0m4.004-14.459a3.583 3.583 0 0 1 5.055.354 21.5 21.5 0 0 1 .67 27.401 3.583 3.583 0 1 1-5.632-4.432 14.334 14.334 0 0 0-.447-18.267 3.583 3.583 0 0 1 .354-5.056M53.52 86.7a3.583 3.583 0 0 1 .424 5.05 14.333 14.333 0 0 0-.191 18.271 3.583 3.583 0 0 1-5.57 4.511 21.502 21.502 0 0 1 .287-27.408 3.583 3.583 0 0 1 5.05-.424m-10.948-9.252a3.583 3.583 0 0 1 .424 5.05 28.667 28.667 0 0 0-.382 36.543 3.584 3.584 0 1 1-5.57 4.51 35.83 35.83 0 0 1 .479-45.679 3.583 3.583 0 0 1 5.05-.424"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCampaignStudioWhite;
