import * as React from "react";
import type { SVGProps } from "react";
const SvgCmsWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M68.237 6.853c4.405 8.647 12.889 23.33 26.267 33.854 20.313 15.989 32.549 34.915 32.549 58.572 0 30.183-23.412 63.059-62.08 63.059-38.667 0-62.243-32.876-62.243-63.059 0-23.657 12.319-42.583 32.55-58.572 13.297-10.36 21.862-24.8 26.267-33.528L63.18 3.67l.89-1.82a1 1 0 0 1 1.799.006l.166.346zM53.965 68.25a3.583 3.583 0 0 1 3.583-3.583h14.669A3.583 3.583 0 0 1 75.8 68.25v9.545c1.289.593 2.524 1.29 3.693 2.084l8.554-4.826a3.583 3.583 0 0 1 4.846 1.299l7.334 12.412a3.583 3.583 0 0 1-1.324 4.944l-8.474 4.78a24.596 24.596 0 0 1 0 4.021l8.474 4.78a3.583 3.583 0 0 1 1.324 4.944l-7.334 12.413a3.583 3.583 0 0 1-4.846 1.298l-8.552-4.825a25.752 25.752 0 0 1-3.695 2.086v9.545a3.583 3.583 0 0 1-3.583 3.583h-14.67a3.583 3.583 0 0 1-3.582-3.583v-9.585a25.752 25.752 0 0 1-3.628-2.058l-8.6 4.851a3.583 3.583 0 0 1-4.846-1.298l-7.334-12.413a3.584 3.584 0 0 1 1.324-4.944l8.538-4.816a24.595 24.595 0 0 1 0-3.976l-8.538-4.817a3.584 3.584 0 0 1-1.324-4.943l7.334-12.413a3.583 3.583 0 0 1 4.846-1.299l8.602 4.853a25.719 25.719 0 0 1 3.626-2.057zm7.166 3.584v8.41a3.583 3.583 0 0 1-2.406 3.385 18.514 18.514 0 0 0-5.807 3.285 3.583 3.583 0 0 1-4.06.372l-7.583-4.279-3.688 6.242 7.464 4.21a3.583 3.583 0 0 1 1.76 3.79 17.44 17.44 0 0 0 0 6.5 3.584 3.584 0 0 1-1.76 3.789l-7.464 4.211 3.688 6.242 7.582-4.278a3.582 3.582 0 0 1 4.06.372 18.537 18.537 0 0 0 5.808 3.286 3.583 3.583 0 0 1 2.406 3.384v8.412h7.502v-8.382c0-1.531.973-2.893 2.42-3.389a18.536 18.536 0 0 0 5.866-3.299 3.583 3.583 0 0 1 4.057-.37l7.533 4.25 3.688-6.242-7.402-4.176a3.584 3.584 0 0 1-1.76-3.792 17.442 17.442 0 0 0 0-6.536 3.583 3.583 0 0 1 1.76-3.792l7.402-4.176-3.688-6.242-7.534 4.25a3.584 3.584 0 0 1-4.057-.369 18.514 18.514 0 0 0-5.865-3.298 3.583 3.583 0 0 1-2.42-3.389v-8.382zM72.06 100.5a7.167 7.167 0 1 1-14.334 0 7.167 7.167 0 0 1 14.334 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCmsWhite;
