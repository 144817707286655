import * as React from "react";
import type { SVGProps } from "react";
const SvgDamColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="url(#DAMColor_svg__a)"
      d="M94.504 40.707C81.126 30.183 72.642 15.5 68.237 6.852l-2.203-4.65-.166-.345a1 1 0 0 0-1.8-.005l-.89 1.819-1.63 3.508c-4.406 8.729-12.971 23.167-26.268 33.528C15.049 56.696 2.73 75.622 2.73 99.279c0 30.183 23.576 63.059 62.244 63.059 38.667 0 62.079-32.876 62.079-63.06 0-23.656-12.236-42.582-32.549-58.571"
    />
    <path
      fill="#fff"
      d="M84.52 73.396c1.058 2.078 3.096 5.606 6.31 8.134 4.88 3.842 7.82 8.389 7.82 14.072 0 6.666-4.752 13.878-12.735 15L71.81 86.422c1.26-1.758 2.879-3.38 4.792-4.892 3.195-2.489 5.253-5.958 6.311-8.055l.392-.843.432-.882.254.53zM32.337 79.405a3.583 3.583 0 0 1 4.9 1.29l25.084 42.999a3.584 3.584 0 0 1-6.19 3.612l-25.084-43a3.583 3.583 0 0 1 1.29-4.901M56.946 80.695a3.583 3.583 0 0 0-6.19 3.61l25.083 43.001a3.584 3.584 0 0 0 6.19-3.612z"
    />
    <defs>
      <linearGradient
        id="DAMColor_svg__a"
        x1={15.105}
        x2={177.127}
        y1={188.882}
        y2={119.696}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212654" />
        <stop offset={0.1} stopColor="#751D5F" />
        <stop offset={0.2} stopColor="#AA1866" />
        <stop offset={0.3} stopColor="#C8146A" />
        <stop offset={0.4} stopColor="#D7136C" />
        <stop offset={0.5} stopColor="#DE126D" />
        <stop offset={0.6} stopColor="#E0126D" />
        <stop offset={0.7} stopColor="#E0126D" />
        <stop offset={0.8} stopColor="#E1116E" />
        <stop offset={0.9} stopColor="#E1116E" />
        <stop offset={1} stopColor="#E1116E" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgDamColor;
