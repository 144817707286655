import * as React from "react";
import type { SVGProps } from "react";
const SvgSeoColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="url(#SeoColor_svg__a)"
      d="M94.534 40.707C81.155 30.183 72.67 15.5 68.266 6.852l-2.203-4.65-.166-.345a1 1 0 0 0-1.8-.005l-.889 1.819-1.631 3.508c-4.406 8.729-12.971 23.167-26.268 33.528-20.231 15.989-32.55 34.915-32.55 58.572 0 30.183 23.576 63.059 62.244 63.059 38.667 0 62.08-32.876 62.08-63.06 0-23.656-12.237-42.582-32.55-58.571"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M79.872 85.638c-5.598-5.597-14.673-5.597-20.27 0-5.598 5.598-5.598 14.673 0 20.271 5.597 5.597 14.672 5.597 20.27 0 5.597-5.598 5.597-14.673 0-20.27m-25.339-5.067c8.397-8.397 22.01-8.397 30.406 0 8.396 8.396 8.396 22.009 0 30.405-7.533 7.533-19.265 8.308-27.661 2.324l-12.88 12.879a3.584 3.584 0 0 1-5.067-5.068l12.879-12.879c-5.984-8.396-5.21-20.128 2.323-27.661"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="SeoColor_svg__a"
        x1={28.62}
        x2={150.54}
        y1={162.338}
        y2={110.817}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#232C61" />
        <stop offset={0.776} stopColor="#45A8AA" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgSeoColor;
