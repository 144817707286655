import * as React from "react";
import type { SVGProps } from "react";
const SvgCloudPlatformColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="url(#CloudPlatformColor_svg__a)"
      d="M94.504 40.707C81.126 30.183 72.642 15.5 68.237 6.852l-2.203-4.65-.166-.345a1 1 0 0 0-1.8-.005l-.89 1.819-1.63 3.508c-4.406 8.729-12.971 23.167-26.268 33.528C15.049 56.696 2.73 75.622 2.73 99.279c0 30.183 23.576 63.059 62.244 63.059 38.667 0 62.079-32.876 62.079-63.06 0-23.656-12.236-42.582-32.549-58.571"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M72.059 68.25c-6.424 0-12.189 2.82-16.125 7.278a14.45 14.45 0 0 0-1.792-.111c-7.916 0-14.333 6.417-14.333 14.333 0 .15.002.3.007.45-6.186 1.588-10.757 7.202-10.757 13.883 0 7.916 6.417 14.334 14.333 14.334h43c7.916 0 14.333-6.418 14.333-14.334 0-5.342-2.92-9.995-7.247-12.46.053-.618.08-1.243.08-1.873 0-11.874-9.625-21.5-21.5-21.5M60.303 81.546c2.598-3.713 6.895-6.13 11.756-6.13 7.916 0 14.333 6.418 14.333 14.334 0 1.118-.127 2.201-.367 3.239a3.583 3.583 0 0 0 2.452 4.235 7.167 7.167 0 0 1-2.085 14.026h-43a7.167 7.167 0 1 1 .415-14.322 3.583 3.583 0 0 0 3.58-4.777 7.151 7.151 0 0 1-.412-2.401 7.167 7.167 0 0 1 7.167-7.167 7.16 7.16 0 0 1 2.151.328 3.583 3.583 0 0 0 4.01-1.365"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M36.225 125.583a3.584 3.584 0 0 0 0 7.167h3.584a3.583 3.583 0 1 0 0-7.167zM46.975 129.167a3.584 3.584 0 0 1 3.584-3.584h3.583a3.584 3.584 0 0 1 0 7.167h-3.583a3.583 3.583 0 0 1-3.584-3.583M64.892 125.583a3.584 3.584 0 0 0 0 7.167h28.667a3.583 3.583 0 1 0 0-7.167z"
    />
    <defs>
      <linearGradient
        id="CloudPlatformColor_svg__a"
        x1={15.105}
        x2={177.127}
        y1={188.882}
        y2={119.696}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212654" />
        <stop offset={0.1} stopColor="#23598B" />
        <stop offset={0.2} stopColor="#247AAF" />
        <stop offset={0.3} stopColor="#258DC5" />
        <stop offset={0.4} stopColor="#2599D2" />
        <stop offset={0.5} stopColor="#259FD8" />
        <stop offset={0.6} stopColor="#25A1DB" />
        <stop offset={0.7} stopColor="#25A2DC" />
        <stop offset={0.8} stopColor="#25A2DC" />
        <stop offset={0.9} stopColor="#26A2DC" />
        <stop offset={1} stopColor="#26A3DD" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgCloudPlatformColor;
