import * as React from "react";
import type { SVGProps } from "react";
const SvgSiteFactoryColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="url(#SiteFactoryColor_svg__a)"
      d="M94.504 40.707C81.126 30.183 72.642 15.5 68.237 6.852l-2.203-4.65-.166-.345a1 1 0 0 0-1.8-.005l-.89 1.819-1.63 3.508c-4.406 8.729-12.971 23.167-26.268 33.528C15.049 56.696 2.73 75.622 2.73 99.279c0 30.183 23.576 63.059 62.244 63.059 38.667 0 62.079-32.876 62.079-63.06 0-23.656-12.236-42.582-32.549-58.571"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M36.226 68.25a3.583 3.583 0 0 0-3.583 3.583v43a3.584 3.584 0 0 0 3.583 3.584h43a3.584 3.584 0 0 0 3.583-3.584v-43a3.583 3.583 0 0 0-3.583-3.583zm3.583 21.5V75.417h35.834V89.75zm21.5 7.167h14.334v14.333H61.309zm-7.166 0v14.333H39.809V96.917z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M97.143 86.167a3.583 3.583 0 1 0-7.167 0v39.416H50.559a3.584 3.584 0 0 0 0 7.167h43a3.583 3.583 0 0 0 3.584-3.583z"
    />
    <defs>
      <linearGradient
        id="SiteFactoryColor_svg__a"
        x1={15.105}
        x2={177.127}
        y1={188.882}
        y2={119.696}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212654" />
        <stop offset={0.1} stopColor="#23598B" />
        <stop offset={0.2} stopColor="#247AAF" />
        <stop offset={0.3} stopColor="#258DC5" />
        <stop offset={0.4} stopColor="#2599D2" />
        <stop offset={0.5} stopColor="#259FD8" />
        <stop offset={0.6} stopColor="#25A1DB" />
        <stop offset={0.7} stopColor="#25A2DC" />
        <stop offset={0.8} stopColor="#25A2DC" />
        <stop offset={0.9} stopColor="#26A2DC" />
        <stop offset={1} stopColor="#26A3DD" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgSiteFactoryColor;
