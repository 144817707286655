import * as React from "react";
import type { SVGProps } from "react";
const SvgMonsidoWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M68.266 6.853c4.405 8.647 12.889 23.33 26.268 33.854 20.312 15.989 32.549 34.915 32.549 58.572 0 30.183-23.413 63.059-62.08 63.059-38.668 0-62.243-32.876-62.243-63.059 0-23.657 12.318-42.583 32.549-58.572 13.297-10.36 21.862-24.8 26.268-33.528l1.631-3.508.89-1.82a1 1 0 0 1 1.799.006l.166.346zM58.02 64.868c-2.944-.885-6.099 1.28-6.099 4.496v6.267l-11.806-3.593c-2.968-.892-6.11 1.373-6.11 4.492v45.99a4.698 4.698 0 0 0 3.346 4.493l29.96 9.118c2.885.879 6.11-1.216 6.11-4.493v-6.267l11.807 3.593c2.885.879 6.11-1.215 6.11-4.492v-45.99a4.7 4.7 0 0 0-3.346-4.494zM41.17 79.85l10.75 3.27v32.233c0 2.054 1.39 3.902 3.354 4.495l10.98 3.341v5.128l-25.084-7.633zm43 41.3-10.75-3.271V85.648a4.7 4.7 0 0 0-3.346-4.493l-10.987-3.343v-5.128l25.083 7.633zm-25.083-7.633 7.167 2.181V87.484l-7.167-2.181z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMonsidoWhite;
