import * as React from "react";
import type { SVGProps } from "react";
const SvgCdpColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="url(#CDPColor_svg__a)"
      d="M94.504 40.707C81.126 30.183 72.642 15.5 68.237 6.852l-2.203-4.65-.166-.345a1 1 0 0 0-1.8-.005l-.89 1.819-1.63 3.508c-4.406 8.729-12.971 23.167-26.268 33.528C15.049 56.696 2.73 75.622 2.73 99.279c0 30.183 23.576 63.059 62.244 63.059 38.667 0 62.079-32.876 62.079-63.06 0-23.656-12.236-42.582-32.549-58.571"
    />
    <path
      fill="#fff"
      d="M72.087 100.499a7.167 7.167 0 1 1-14.334 0 7.167 7.167 0 0 1 14.334 0"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M64.892 79a21.502 21.502 0 0 0-15.203 36.702 21.501 21.501 0 0 0 35.066-6.975A21.497 21.497 0 0 0 73.12 80.636a21.502 21.502 0 0 0-8.228-1.637m-5.485 8.257a14.333 14.333 0 1 1 10.97 26.485 14.333 14.333 0 0 1-10.97-26.485"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M51.18 67.394a35.833 35.833 0 1 1 27.425 66.21 35.833 35.833 0 0 1-27.426-66.21m13.712 4.439a28.667 28.667 0 1 0 0 57.334 28.667 28.667 0 0 0 0-57.334"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="CDPColor_svg__a"
        x1={15.105}
        x2={177.127}
        y1={188.882}
        y2={119.696}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212654" />
        <stop offset={0.1} stopColor="#751D5F" />
        <stop offset={0.2} stopColor="#AA1866" />
        <stop offset={0.3} stopColor="#C8146A" />
        <stop offset={0.4} stopColor="#D7136C" />
        <stop offset={0.5} stopColor="#DE126D" />
        <stop offset={0.6} stopColor="#E0126D" />
        <stop offset={0.7} stopColor="#E0126D" />
        <stop offset={0.8} stopColor="#E1116E" />
        <stop offset={0.9} stopColor="#E1116E" />
        <stop offset={1} stopColor="#E1116E" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgCdpColor;
