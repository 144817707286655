import * as React from "react";
import type { SVGProps } from "react";
const SvgDrupalCloudAddOnWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 153 153"
    {...props}
  >
    <path
      stroke="#fff"
      strokeWidth={10.625}
      d="M33.285 44.048C45.008 35.078 52.9 23.102 57.46 14.813c4.55 8.23 12.341 20.228 24 29.23 17.955 13.874 27.979 29.626 27.979 48.864 0 24.907-19.703 51.819-51.988 51.819-32.302 0-52.137-26.932-52.137-51.82 0-19.225 10.085-34.977 27.972-48.858Z"
    />
    <circle
      cx={109.969}
      cy={107.009}
      r={36.656}
      fill="#fff"
      stroke="#fff"
      strokeWidth={12.75}
    />
    <path
      stroke="#26A3DD"
      strokeLinecap="round"
      strokeWidth={10.625}
      d="M109.969 87.884v38.25M90.844 107.009h38.25"
    />
  </svg>
);
export default SvgDrupalCloudAddOnWhite;
