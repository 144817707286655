import * as React from "react";
import type { SVGProps } from "react";
const SvgMonsidoColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="url(#MonsidoColor_svg__a)"
      d="M94.534 40.707C81.155 30.183 72.67 15.5 68.266 6.852l-2.203-4.65-.166-.345a1 1 0 0 0-1.8-.005l-.889 1.819-1.631 3.508c-4.406 8.729-12.971 23.167-26.268 33.528-20.231 15.989-32.55 34.915-32.55 58.572 0 30.183 23.576 63.059 62.244 63.059 38.667 0 62.08-32.876 62.08-63.06 0-23.656-12.237-42.582-32.55-58.571"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M54.423 69.363c0-3.217 3.154-5.38 6.098-4.496l29.972 9.12a4.7 4.7 0 0 1 3.346 4.494v45.99c0 3.277-3.225 5.371-6.11 4.492l-11.806-3.593v6.268c0 3.277-3.226 5.371-6.11 4.492l-29.961-9.117a4.7 4.7 0 0 1-3.346-4.493V76.53c0-3.12 3.142-5.385 6.11-4.493l11.807 3.593zm0 13.758-10.75-3.27v40.833l25.083 7.633v-5.127l-10.98-3.342c-1.963-.592-3.354-2.441-3.354-4.495zm21.5 34.758 10.75 3.272V80.316l-25.084-7.633v5.128l10.987 3.343a4.7 4.7 0 0 1 3.347 4.494zm-7.167-2.181-7.167-2.18V85.302l7.167 2.18z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="MonsidoColor_svg__a"
        x1={15.134}
        x2={177.156}
        y1={188.882}
        y2={119.696}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#212654" />
        <stop offset={0.1} stopColor="#23598B" />
        <stop offset={0.2} stopColor="#247AAF" />
        <stop offset={0.3} stopColor="#258DC5" />
        <stop offset={0.4} stopColor="#2599D2" />
        <stop offset={0.5} stopColor="#259FD8" />
        <stop offset={0.6} stopColor="#25A1DB" />
        <stop offset={0.7} stopColor="#25A2DC" />
        <stop offset={0.8} stopColor="#25A2DC" />
        <stop offset={0.9} stopColor="#26A2DC" />
        <stop offset={1} stopColor="#26A3DD" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgMonsidoColor;
