import * as React from "react";
import type { SVGProps } from "react";
const SvgSiteFactoryWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 128 163"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M68.237 6.853c4.405 8.647 12.889 23.33 26.267 33.854 20.313 15.989 32.549 34.915 32.549 58.572 0 30.183-23.412 63.059-62.08 63.059-38.667 0-62.243-32.876-62.243-63.059 0-23.657 12.319-42.583 32.55-58.572 13.297-10.36 21.862-24.8 26.267-33.528L63.18 3.67l.89-1.82a1 1 0 0 1 1.799.006l.166.346zm-35.595 64.98a3.583 3.583 0 0 1 3.583-3.583h43a3.583 3.583 0 0 1 3.584 3.584v42.999a3.583 3.583 0 0 1-3.584 3.584h-43a3.583 3.583 0 0 1-3.583-3.584zm7.167 3.584V89.75h35.833V75.417zm35.833 21.5H61.309v14.333h14.333zm-21.5 14.333V96.917H39.809v14.333zm39.417-28.666a3.583 3.583 0 0 1 3.583 3.583v43a3.583 3.583 0 0 1-3.583 3.583h-43a3.583 3.583 0 1 1 0-7.167h39.416V86.167a3.583 3.583 0 0 1 3.584-3.584"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSiteFactoryWhite;
